/**
 * SPDX-FileCopyrightText: (c) 2000 Liferay, Inc. https://liferay.com
 * SPDX-License-Identifier: LGPL-2.1-or-later OR LicenseRef-Liferay-DXP-EULA-2.0.0-2023-06
 */

import {FromParameters, FromUserAgentApplication} from './OAuth2Client';

window.Liferay = window.Liferay || {};

window.Liferay.OAuth2Client = {

	// Definitions are declared in liferay.d.ts
	// @ts-ignore

	FromParameters,

	// Definitions are declared in liferay.d.ts
	// @ts-ignore

	FromUserAgentApplication,
};
